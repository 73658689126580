import React from "react";
import VirtualTable from "./virtual-table";
import {Empty, Typography} from "antd";
import {useAppStore} from "../../../../hooks/useApp.hook";
import {useRecordStore} from "../../../../hooks/useRecords.hook";

const generateColumn = col => ({
	custom: !!col.custom,
	fieldData: col,
	align: 'center',
	sorter: true,
	title: <Typography.Text style={{fontSize: 12}}>{col.label ? col.label.toUpperCase() : ''}</Typography.Text> ,
	key: col.key,
	dataIndex: col['data_name'],
	ellipsis: {
		showTitle: false,
	},
	// render: item => {
	// 	return null;
	// }
});

let tm;

const TablePane = (props) => {
	const {state: {fields}} = useAppStore();
	const {
		state: {selectedRecords, preview, activeRecord},
		actions: {setSelectedRecords, setPreview, setActiveRecord}
	} = useRecordStore();
	const {state: {app, ...appState}} = useAppStore();
	let {height, records, loading, onSort} = props;

	const columns = fields.map(generateColumn);

	const rowSelection = {
		selectedRows: selectedRecords,
		onChange: res => setSelectedRecords(res),
		fixed: true,
		width: 40
	};

	React.useEffect(() => {
		if (window.innerHeight - height <= 60) {
			props.onTable &&
			props.onTable()
		}
	}, []);

	if (appState.loading)
		return <Empty />;

	return (
		<>
			{height <= 50 && (
				<div style={{width: `calc(100vw - 220px)`, height: 50, backgroundColor: '#FFF', position: 'absolute', zIndex: 4, bottom: 0, right: 0, borderTop: '4px solid #2B79C9'}} />
			)}
			<VirtualTable
				activeItem={activeRecord}
				onView={setPreview}
				onHover={item =>  {
					clearTimeout(tm);
					if (!activeRecord && !item) return;
					if ((!!activeRecord && !item) || (!activeRecord && !!item)) tm = setTimeout(() => setActiveRecord(item), 300);
					if (activeRecord && item && activeRecord.id === item.id) return;
					tm = setTimeout(() => setActiveRecord(item), 300);
				}}
				columns={columns.filter(col => col.fieldData ? col.fieldData.hidden !== true : true)}
				dataSource={records}
				rowKey={'id'}
				rowSelection={rowSelection}
				loading={loading}
				scroll={{
					y: height - 40,
				}}
				onChange={(p, f, s) => {
					if (s) {
						if (s.order)
							onSort && onSort({
								field: s.field,
								order: s.order === 'ascend' ? 'ASC' : 'DESC'
							});
						else onSort && onSort(null);
					}
				}}
			/>
		</>
	);
};

export default TablePane;
