import React from "react";
import mapboxgl from "mapbox-gl";

let popup = new mapboxgl.Popup({
	closeButton: false,
	closeOnClick: false
});

const Layer = ({map, name, source, onClick, onMouseOver, preview}) => {

	React.useEffect(() => {
		if (preview) {
			const description = (
				preview['_title'] || ''
			) + (
				preview['record_job_address'] ? ' - ' + preview['record_job_address'] : ''
			);
			popup.setLngLat([preview['_longitude'], preview['_latitude']]).setHTML(description).addTo(map);
		} else {
			popup.remove();
		}
	})

	React.useEffect(() => {
		const handleMove = (e, enter) => {
			if (enter) {
				// const containers = document.getElementsByClassName(e.features[0].properties.id);
				// Object.keys(containers).forEach(c => containers[c].classList.add("active-column"));
				// const coordinates = e.features[0].geometry.coordinates.slice();
				// const description = (
				// 	e.features[0].properties['_title'] || ''
				// ) + ' - ' + (
				// 	e.features[0].properties['record_job_address'] || ''
				// );
				e.target.getCanvas().style.cursor = 'pointer';
				// popup.setLngLat(coordinates).setHTML(description).addTo(map);
				onMouseOver && onMouseOver(e.features[0].properties);
			} else {
				// const containers = document.getElementsByClassName("active-column");
				// console.log(Object.keys(containers), containers);
				// Object.keys(containers).forEach(c => containers[c] && containers[c].classList && containers[c].classList.remove("active-column"));
				onMouseOver && onMouseOver(null);
				e.target.getCanvas().style.cursor = '';
			}
		};

		const handleClick = function (e) {
			const feature = e.features[0];
			onClick && onClick(feature.properties);
		};

		try {
			if (!map.getLayer(name)) {
				map.addLayer({
					id: name,
					type: 'circle',
					source: source,
					paint: {
						'circle-radius': 4,
						'circle-color': [
							'match',
							['get', '__is_selected_yn'],
							'y', '#2E86E1',
							'n', ['get', 'color'],
							'#000'
						],
						'circle-stroke-color': [
							'match',
							['get', 'selected'],
							'y', '#0F0',
							'n', '#000',
							'#000'
						],
						'circle-stroke-width': 1.5,
						'circle-opacity': 0.9
					},
				});
				map.on('mouseenter', name, e => handleMove(e, true));
				map.on('mouseleave', name, e => handleMove(e, false));
				map.on('click', name, handleClick);
			}
		} catch (e) {
			console.log(e.message);
		}

		return () => {
			map.off('mouseenter', name, e => handleMove(e, true));
			map.off('mouseleave', name, e => handleMove(e, false));
			map.off('click', name, handleClick);
			map.removeLayer(name);
		}
	}, []);

	return null;
};

export default Layer;
