import React from "react";
import {Card, DatePicker, Image, Upload, Button, Input, Select, Tag, List, message} from "antd";
import moment from "moment";
import FulcrumObject from "./fulcrum-object";
import {UploadOutlined} from "@ant-design/icons";
import {useAppStore} from "../hooks/useApp.hook";

function uuid() {
  function _p8(s) {
    let p = (Math.random().toString(16)+"000000000").substr(2,8);
    return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
}


export const ChoiceListDataSelect = ({choice_list_id, value, route, onChange, mode, choices, filterValues, disabled}) => {
  const SelectItem = ({loading, choices}) =>(
    <Select
      disabled={disabled}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={value}
      onChange={onChange} loading={loading} allowClear mode={mode}>
      {choices && choices
        .filter(c => filterValues ? !filterValues.includes(c.value) : true)
        .map(choice => (
        <Select.Option value={choice.value} key={choice.value}>
          {choice.label}
        </Select.Option>
      ))}
    </Select>
  );

  return choices && Array.isArray(choices) ? <SelectItem choices={choices} /> : (
    <FulcrumObject route={route || 'choiceLists'} id={choice_list_id}>
      {({data, loading}) => {
        return <SelectItem loading={loading} choices={data && (data.choices || data.items)} />;
      }}
    </FulcrumObject>
  );
};

const DateChooser = ({onChange, value, disabled}) => (
  <DatePicker
    disabled={disabled}
    style={{width: '100%'}}
    value={value ? moment.utc(value) : null}
    format="YYYY-MM-DD"
    onChange={(res, date) => onChange(date)}
  />
);

const ChoiceFieldInput = ({onChange, value, field, filterValues, disabled}) => {
  if (field['choice_list_id'])
    return <ChoiceListDataSelect
      disabled={disabled}
      choice_list_id={field['choice_list_id']}
      mode={field.multiple === true && 'multiple'}
      onChange={onChange}
      value={value}
      filterValues={filterValues}
    />;

  if (field['classification_set_id'])
    return <ChoiceListDataSelect
      disabled={disabled}
      route={'classificationSets'}
      choice_list_id={field['classification_set_id']}
      mode={field.multiple === true && 'multiple'}
      onChange={onChange}
      value={value}
      filterValues={filterValues}
    />;

  if (field['choices'])
    return <ChoiceListDataSelect
      disabled={disabled}
      choices={field.choices}
      mode={field.multiple === true && 'multiple'}
      onChange={onChange}
      value={value}
      filterValues={filterValues}
    />;
  return null;
};

const ImageField = ({edit, value, onChange, disabled}) => {
  let auth = localStorage.getItem('auth');
  try {
    value = JSON.parse(value);
  } catch (e) {}

  auth = JSON.parse(auth);

  if(Array.isArray(value))
    value = value;
  else if (typeof value === 'string' && /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/.test(value))  // checks if uuid
    value = [value];
  else
    value = [];

  const props = {
    disabled,
    name: 'file',
    listType: 'picture',
    defaultFileList: value.map(v => ({
      uid: v,
      name: v,
      status: 'done',
      response: 'Server Error 500', // custom error message to show
      url: `https://api.fulcrumapp.com/api/v2/photos/${v}.jpg?token=${auth.token}`,
    })),
    customRequest: (options) => {
      const data = new FormData();
      const id = uuid();
      data.append("photo[access_key]", id);
      data.append("photo[file]", options.file);
      return fetch('https://api.fulcrumapp.com/api/v2/photos',{
        method: 'POST',
        "headers": {
          // "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
          'Accept': 'application/json',
          'X-ApiToken': auth.token,
        },
        body: data
      }).then(res => res.json())
        .then(res => res['photo']['access_key'])
        .then(res => {
        options.onProgress({percent: 100});
        options.onSuccess(res, options.file);
      })
        .catch(e => options.onError(e));
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        onChange && onChange([...value, info.file.response]);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === 'removed') {
        onChange && onChange(value.filter(v => v !== info.file.uid));
      }
    },
  };

  if (edit)
    return (
      <Upload {...props}>
        <Button disabled={disabled} size={'small'} style={{margin: 6}} icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    );
  else
    return (
      <List
        style={edit ? {border: '1px solid rgba(0,0,0,0.1)', borderRadius: 3} : {}}
        grid={{gutter: 16, column: 4}}
        dataSource={value || []}
        renderItem={item => (
          <Card
            key={'trigger'}
            size={'small'}
            style={{margin: 'auto 3px '}}
            bodyStyle={{padding: 0, textAlign: 'center'}}>
            <Image
              src={`https://api.fulcrumapp.com/api/v2/photos/${item}.jpg?token=${auth.token}`} height={50} width={50} />
          </Card>
        )}
      />
    );
};


const FulcrumField = ({field, edit, value, onChange, noStyle, style, filterValues, disabled}) => {
  let child = null;
  value = value === 'null' ? null : value;

  switch(field.type) {
    case 'CalculatedField':
      if (field.display.style === 'date')
        return (
          <FulcrumField
            field={{...field, type: 'DateTimeField'}}
            edit={edit}
            value={value}
            noStyle={noStyle}
            onChange={onChange}
            filterValues={filterValues}
            disabled={disabled}
            style={style}
          />
        );
      child = edit ?
        (<Input type={field.display.style} allowClear value={value} onChange={onChange} disabled={disabled || edit} />) :
        (value || '_');
      break;
    case 'TextField':
      child = edit ?
        (<Input disabled={disabled} type={field.numeric ? 'number' : 'text'}  value={value} onChange={onChange} allowClear />) :
        (value || '_');
      break;
    case 'DateTimeField':
      child = edit ?
        (<DateChooser disabled={disabled} value={value} onChange={onChange} />) :
        (value ? moment.utc(value, true).format('YYYY-MM-DD') : '_');
      break;
    case 'YesNoField':
      child = edit ? (
        <Select disabled={disabled} allowClear value={value} onChange={onChange}>
          <Select.Option value={'yes'}>Yes</Select.Option>
          <Select.Option value={'no'}>No</Select.Option>
        </Select>
      ) : (value ? value.toUpperCase() : '_');
      break;
    case 'ClassificationField':
    case 'ChoiceField':
      child = edit ?
        <ChoiceFieldInput disabled={disabled} filterValues={filterValues} value={value} onChange={onChange} field={field} /> :
        (value || '_');
      break;
    case 'PhotoField':
      child = <ImageField disabled={disabled} edit={edit} value={value} onChange={onChange} style={style} />;
      break;
    case 'VideoField':
      child = null;
      break;
    default:
      child = edit ? null : field.type;
  }

  return (
    <div style={style}>
      {(!edit && !noStyle) ? <Tag style={{width: '100%', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis'}}>{(child || null)}</Tag> : (child || null)}
    </div>
  );
};


export default FulcrumField;

