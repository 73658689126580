import React from "react";
import {Radio, Tooltip} from "antd";
import {BorderBottomOutlined, BorderTopOutlined, BorderVerticleOutlined} from "@ant-design/icons";


const WindowControls = ({setSize}) => (
		<Radio.Group onChange={e => {
			setSize(e.target.value);
			// setTimeout(() => map && map.resize(), 100);
		}} style={{borderRadius: 5, overflow: 'hidden', marginTop: 5}}>
			<Radio.Button value={50} >
				<Tooltip title={"Map View"} placement={'bottom'}>
					<BorderBottomOutlined/>
				</Tooltip>
			</Radio.Button>
			<Radio.Button value={window.innerHeight / 2} >
				<Tooltip title={'Split View'} placement={'bottomLeft'}>
					<BorderVerticleOutlined/>
				</Tooltip>
			</Radio.Button>
			<Radio.Button value={window.innerHeight - 50}>
				<Tooltip title={'Table View'} placement={'bottomLeft'}>
					<BorderTopOutlined/>
				</Tooltip>
			</Radio.Button>
		</Radio.Group>
	);

export default WindowControls;
