import React from "react";

const Loading = () => {

	return (
		<div className="page-loader">
			<div className="loader">
				Loading, please wait...
			</div>
		</div>
	);
};

export default Loading;
