import {createContext, useContext, useReducer} from "react";
import {Client} from "fulcrum-app";
import {getMembers, getStatuses} from "../helpers";
import {message} from "antd";
import React from "react";

const initState = () => ({
	app: null,
	loading: localStorage.getItem('authToken'),
	fields: [],
	showOnlyFields: [],
	form: null,
});

const actions = {
	loading: 'loading_app',
	set_app: 'set_app',
	set_fields: 'set_fields',
	set_show_fields: 'set_show_fields',
	reset: 'reset',
};

const reducer = (state = initState(), action) => {
	switch (action.type) {
		case actions.loading:
			return {
				...state,
				loading: action.loading
			};
		case actions.reset:
			return initState();
		case actions.set_app:
			return {
				...state,
				app: action.app,
				fields: action.fields,
				form: action.form
			};
		case actions.set_fields:
			return {
				...state,
				fields: action.fields,
			};
		case actions.set_show_fields:
			return {
				...state,
				showOnlyFields: [...action.fields],
			};
		default:
			return state;
	}
};

const StoreContext = createContext(null);

export const AppStoreProvider = ({children}) => {
	const [state, dispatch] = useReducer(reducer, null, initState);

	const setLoading = loading => dispatch({
		type: actions.loading,
		loading
	});

	const setFields = fields => dispatch({
		type: actions.set_fields,
		fields
	});

	const setApp = async (app, {auth, user, reload}) => {
		dispatch({type: actions.reset});
		localStorage.setItem('app', app.id);
		if (reload === true)
			window.location.reload(false);
		else
			await loadApp(app, {auth, user});
	};

	const updateVisibleFields = fields => ({
		type: actions.set_show_fields,
		fields
	});

	const loadApp = async (app, {auth, user}) => {
		setLoading(true);
		const client = new Client(auth.token);
		try {
			const form = await client.forms.find(app.formId);
			if (form) {
				app.subForm = app.subFormField && form.elements.filter(r => r['data_name'] === app.subFormField)[0];
				let fields = app.subForm ? app.subForm.elements : form.elements;

				app.tableName = app && `${app.formId}${app.subFormField ? '/' + app.subFormField : ''}`;
				app.form = form;

				let formattedFields = [];

				for (let i in fields) {
					const field = fields[i];
					if (field.type === 'Section') {
						field.elements.forEach(i => formattedFields.push(i));
					} else {
						formattedFields.push(field);
					}
				}

				app.titleKeys = app.subFormField ?
					app.subForm['title_field_keys'] : form['title_field_keys'];

				fields = [
					...formattedFields
				];

				if (!app.subFormField) {
					if (form['assignment_enabled']) {
						fields = [await getMembers(client), ...fields]
					}
					if (form['projects_enabled']) {
						fields = [await getMembers(client), ...fields]
					}
					fields = [getStatuses(form), ...fields];
				}

				const props = {auth, user, fields};

				if (app.isContractor && typeof app.isContractor === "function") {
					app.isContractor = app.isContractor(props)
				}

				if (app.defaultQuery && typeof app.defaultQuery === 'function') {
					app.defaultQuery = app.defaultQuery(props);
				}

				if (app.updateFields && typeof app.updateFields === 'function') {
					app.updateFields = app.updateFields(props);
				}

				if (app.hideFields && typeof app.hideFields === 'function') {
					app.hideFields = app.hideFields(props);
					fields = fields.filter(f => app.hideFields ? !app.hideFields.includes(f.data_name) : true)
				}

				if (app.filterFieldValues && typeof app.filterFieldValues === 'function') {
					app.filterFieldValues = app.filterFieldValues(props);
				}
				dispatch({type: actions.set_app, app, fields: fields.map(f => ({...f, hidden: false})), form});
			}
		} catch (e) {
			message.error('Access is restricted to this app.');
			localStorage.removeItem('app');
			dispatch({type: actions.reset});
		}
		setLoading(false);
	}

	return (
		<StoreContext.Provider
			value={{
				state,
				actions: {
					setApp,
					setFields,
					updateVisibleFields
				}
			}}>
			{children}
		</StoreContext.Provider>
	);
};

export const useAppStore = () => useContext(StoreContext);
