import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import Pages from "./pages";
import './index.css';
import {AuthStoreProvider} from "./hooks/useAuth.hook";
import {AppStoreProvider} from "./hooks/useApp.hook";
import {RecordStoreProvider} from "./hooks/useRecords.hook";


// eslint-disable-next-line no-extend-native
String.prototype.trunc = function(num) {
  const val = this.split(/ /g);
  if (val.length <= num) {
    return this;
  }
  return val.slice(0, num).toString().replace(/,/g, ' ') + '...'
};

ReactDOM.render(
  <BrowserRouter>
    <AppStoreProvider>
      <AuthStoreProvider>
        <RecordStoreProvider>
          <Pages />
        </RecordStoreProvider>
      </AuthStoreProvider>
    </AppStoreProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
