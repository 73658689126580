import {Button, Card, Form} from "antd";
import React from "react";
import FulcrumField from "./fulcrum-fields";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {UnsupportedFieldTypes} from "../config";
import {useAppStore} from "../hooks/useApp.hook";


const Section = ({field, children}) => {
	const [show, setShow] = React.useState(false);

	return (
		<Card
			extra={<Button size={'small'} onClick={() => setShow(!show)} icon={!show ? <CaretDownOutlined/> : <CaretUpOutlined />} />}
			title={field.label}
			size={'small'}
			style={{margin: '10px 0px'}}
			bodyStyle={{padding: show ? 6 : 0, height: show ? undefined : 0, overflow: show ? 'visible' : 'hidden'}}>
			{children}
		</Card>
	)
};



export const FulcrumForm = ({app, elements, filterFields = [], edit, keyField, hideFields, filterFieldValues, ...props}) => {
	elements = elements || [];
	keyField = keyField || 'key';

	const store = useAppStore();

	app = store ? store.state.app : app;
	
	const getFormItems = elements => {
		return elements
			.map(elem => {
				let child = null;
				let name = null;

				if (UnsupportedFieldTypes.includes(elem.type))
					return null;

				switch (elem.type) {
					case 'Section':
						const fis = getFormItems(elem.elements);
						return !fis.every(fi => fi === null) ? (
							<Section key={elem['key']} field={elem}>
								{fis}
							</Section>
						) : null;
					default:
						name = elem[keyField];


						const disabled = app.fieldDisableConditions ?
							(
								app.fieldDisableConditions[elem['data_name']] &&
								props.initialValues &&
								app.fieldDisableConditions[elem['data_name']](props.initialValues)
							) : (
								props.initialValues
							);

						child = (
							<FulcrumField
								disabled={(app.isContractor && props?.initialValues?.["restoration_status"] === "Complete") || disabled}
								key={elem['key']}
								edit={edit}
								field={elem}
								filterValues={filterFieldValues && filterFieldValues[elem['data_name']]}
							/>
						);
				}

				if (filterFields && filterFields.length > 0 && !filterFields.includes(elem['data_name']))
					return null;

				if (hideFields && hideFields.length > 0 && hideFields.includes(elem['data_name']))
					return null;

				return child ? (
					<Form.Item
						key={elem['key']}
						name={name}
						label={elem.label}
						wrapperCol={{span: 24}}
						labelCol={{span: 24}}>
						{child}
					</Form.Item>
				) : null;
			})
	};

	return (
		<Form {...props}>
			{getFormItems(elements)}
		</Form>
	);
};

export default FulcrumForm;
