import React from 'react';
import {Button, Card, Input, Space, Typography} from "antd";

const HoleRefSearch = ({getData, query, app}) => {
  const [search, setSearch] = React.useState();
  const isSubForm = app?.subFormField === 'restoration_holes';

  const searchData = (value) => {
    if (value && value !== '') {
      value = value.split(/\n/g);
      value = value.filter(v => v !== '' && !!v);
      value = value.map(v => v.trim().replace(/ /g, '').replace(/,/g, ''));
      if (value && value.length > 0) {
          getData({filters: {...(query.filters || {}), [isSubForm ? "hole_ref_" : "child"]: isSubForm ? value : {hole_ref_: value}}})
      } else {
          getData({filters: {...(query.filters || {})}})
      }
    } else {
      delete query.filters[isSubForm ? "hole_ref_" : "child"];
      getData(query);
    }
  }

  return (
    <Card bodyStyle={{padding:6}}>
      <Space direction={'vertical'} style={{width: '100%'}}>
        <Typography.Text>
          Job Address Search:
        </Typography.Text>
        <Input.Search
          allowClear
          placeholder={'Job Address'}
          onSearch={(v) => {
            if (!v || v === '')
              getData({filters: {}});
            else
              getData({filters: {[isSubForm ? 'upper(record_job_address)' : 'upper(job_address)']: `like '%${v.toUpperCase()}%'`}});
          }} />
        <Typography.Text>
            Hole Ref # Search:
        </Typography.Text>
        <Input.TextArea
          value={search}
          placeholder={'Enter reference numbers separated by comma (,)'}
          allowClear
          onChange={e => {
            e.preventDefault();
            const v = e.target.value;
            setSearch(v);
            if (!v || v === "") searchData()
          }}
        />
        <Button block size={'small'} onClick={() => searchData(search)}>
          Search
        </Button>
      </Space>
    </Card>
  );
}

export default HoleRefSearch;
