import React from "react";
import {Avatar, Button, Form, Input, Layout, List, Modal, Space, Typography} from "antd";
import Logo from '../assets/maestro.png';
import {ArrowRightOutlined} from "@ant-design/icons";
import {useAuthStore} from "../hooks/useAuth.hook";
import {apps} from "../config";
import {Redirect} from "react-router-dom";
import qs from "qs"

const LoginPage = ({history, location}) => {
	const {state: {user, auth}, actions: {login, setOrg}} = useAuthStore();

	const {redirect} = qs.parse(location.search.replace("?", ""))

	const [creds, setCreds] = React.useState(null);
	const [authError, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const authenticate = (creds) => {
		setLoading(true);
		login(creds)
			.then(() => {
				setLoading(false);
			}).catch(err => {
				setError(err.message);
				setLoading(false);
		});
	};

	const selectOrg = (org) => {
		setLoading(true);
		setOrg(org, creds)
			.then(() => {
				setLoading(false);
				history.push(redirect || '/');
			});
	};

	return (
		<Layout style={{height: '100vh', backgroundColor: '#FFF', alignItems: 'center', justifyContent: 'center'}}>
				{auth && <Redirect to={redirect || '/'} />}
				<Space direction={'vertical'} size={30}>
					<div style={{width: '100%', textAlign: 'center'}}>
						<img src={Logo} alt={'Maestro by Sequel Logo'} />
					</div>
					<Form
						labelCol={{span: 24}}
						wrapperCol={{span: 24}}
						onValuesChange={(t, a) => setCreds(a)}
						onFinish={authenticate}>
						<Form.Item
							name="email"
							label="Email"
							rules={[{required: true, message: 'Please input your email!'}]}
						>
							<Input disabled={!!user}/>
						</Form.Item>

						<Form.Item
							name="password"
							label="Password"
							rules={[{required: true, message: 'Please input your password!'}]}
						>
							<Input.Password disabled={!!user}/>
						</Form.Item>

						<Form.Item style={{textAlign: 'center'}}>
							<Typography.Text style={{color: 'red'}}>
								{authError}
							</Typography.Text>
						</Form.Item>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								block
								style={{margin: '20px 0'}}
								loading={loading}
								disabled={!!user}
							>
								Sign In
							</Button>
						</Form.Item>
					</Form>
					<Modal size={'small'} title={'Select Organization'} visible={!!user && !auth} footer={null}>
						<List
							dataSource={user ? user.orgs.filter(o => apps.map(a => a.org).includes(o.id)) : []}
							renderItem={item => {
								return (
									<List.Item
										actions={[
											<Button onClick={() => selectOrg(item)} icon={<ArrowRightOutlined />} size={'small'}/>
										]}>
										<List.Item.Meta
											avatar={<Avatar src={item.image_small} />}
											title={item.name}
										/>
									</List.Item>
								);
							}}
						/>
					</Modal>
				</Space>
		</Layout>
	);
};

export default LoginPage;
