import React, {useRef, useState} from "react";
import {VariableSizeGrid as Grid} from "react-window";
import classNames from "classnames";
import {Checkbox, Table as AntTable, Button} from "antd";
import ReactResizeObserver from "rc-resize-observer";
import {EyeOutlined} from "@ant-design/icons";
import FulcrumField from "../../../../components/fulcrum-fields";

function VirtualTable({activeItem, onHover, ...props}) {
  const { columns, scroll, className, rowSelection, rowKey, onView} = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length;

  const gridRef = useRef();
  const [selection, setSelection] = useState([]);

  const getSelectedRecord = () => {
    return rowSelection.selectedRows || selection;
  };

  const resetVirtualGrid = () => {
    gridRef.current && gridRef.current.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };


  React.useEffect(() => resetVirtualGrid, [tableWidth]);

  const updateSelection = (records) => {
    let recs = [...getSelectedRecord()];
    if (Array.isArray(records)) {
      recs = records;
    } else {
      if (isSelected(records)) {
        recs = recs.filter(r => r[rowKey] !== records[rowKey]);
      } else {
        recs.push(records);
      }
    }
    setSelection(recs);
    if (rowSelection.onChange)
      rowSelection.onChange(recs);
  };

  const isSelected = (rec) => {
    return rec['__is_selected'];
  };

  const isAllSelected = () => {
    const selection = props.dataSource.map(d => d['__is_selected']);
    return selection.length > 0 ? selection.every(s => s === true) : false;
  };

  const mergedColumns = [
    ...(!!rowSelection ? [{
      title: () => {
        const selected = isAllSelected();
        return (
          <Checkbox
            disabled={props.dataSource.length === 0}
            indeterminate={selected === false && getSelectedRecord().length > 0}
            checked={isAllSelected()}
            onChange={() => updateSelection(selected !== true ? props.dataSource : [])}
          />
        );
      },
      key: 'checkbox',
      fixed: 'left',
      width: 40,
      render: (pps) => <Checkbox {...pps}  />
    }] : []),
    {
      title: () => <EyeOutlined style={{margin: 4}}/>,
      key: 'preview',
      fixed: 'left',
      width: 40,
      render: ({preview}) => {
        return <Button size={'small'} icon={<EyeOutlined/>} onClick={() => preview()} />;
      }
    },
    {
      title: () => '',
      key: 'color',
      fixed: 'left',
      width: 10,
      render: ({data, preview}) => {
        return <div style={{backgroundColor: data['color'], height: '100%', width: '100%'}}/>;
      }
    },
    ...columns.map(column => ({
      ...column,
      width: 300
    }))
  ].map(col => {
      if (!col.width)
        col.width = Math.floor(tableWidth / widthColumnCount)
      return col;
  });

  const formatRowData = (data, {column}) => {
    return data ? (
      <FulcrumField
        field={column.fieldData}
        value={data}
        noStyle
        style={{margin: 6, height: 40}}
      />
    ) : null;
  };

  const renderVirtualList = (rawData, {onScroll}) => {
    return (
      <Grid
        size={'small'}
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(t) => (mergedColumns[t].width || 0)}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 50}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style}) => {
          let data = rawData[rowIndex][mergedColumns[columnIndex].dataIndex];
          const column = mergedColumns[columnIndex];
          const dataIndex = column.dataIndex;
          return (
            <div
              className={classNames('virtual-table-cell' + rawData[rowIndex].id, {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                'active-item': activeItem && activeItem.id === rawData[rowIndex].id
              })}
              onMouseEnter={() => onHover && onHover(rawData[rowIndex])}
              onMouseLeave={() => onHover && onHover()}
              style={{
                ...style,
                borderRight:  '0.1px solid rgba(0,0,0,0.1)',
                borderBottom: '0.1px solid rgba(0,0,0,0.1)',
                width: column.width,
                fontSize: 10,
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {
                !!dataIndex ?
                (formatRowData(data, {column, dataIndex})) :
                column.render({
                  data: rawData[rowIndex],
                  onChange: () => updateSelection(rawData[rowIndex]),
                  checked: isSelected(rawData[rowIndex]),
                  preview: () => onView && onView(rawData[rowIndex])
                })
              }
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ReactResizeObserver
      onResize={({ width, height }) => {
        setTableWidth(width);
      }}
    >
      <AntTable
        {...props}
        scroll={{
          ...scroll,
          x: tableWidth
        }}
        size={'small'}
        rowSelection={null}
        bordered
        className={classNames(className, 'virtual-table')}
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ReactResizeObserver>
  );
}

export default VirtualTable;
