import React from "react";
import {Card, List, Tag, Typography} from "antd";
import {DatabaseOutlined} from "@ant-design/icons";
import {useRecordStore} from "../../../hooks/useRecords.hook";

const QueuedRecords = () => {
	const {state: {queuedRecords}} = useRecordStore();

	return (
		<div className={'ant-card '} style={{padding: 6, height: '100%', overflow: 'auto'}}>
			<Card.Meta
				avatar={<DatabaseOutlined/>}
				title={<Typography.Text style={{fontSize: 13}}>Update Queue</Typography.Text>}
				description={
					<Typography.Text style={{fontSize: 11, color: '#999'}}>
						total left: {queuedRecords.filter(r => !['completed', 'failed', 'timeout'].includes(r.status)).length}
					</Typography.Text>
				}
			/>
			<List
				style={{maxHeight: 250, overflowY: 'auto', overflowX: 'hidden'}}
				size={'small'}
				dataSource={queuedRecords}
				renderItem={item => (
					<List.Item style={{fontSize: 10, padding: 2}}>
						<Typography.Text style={{width: 150, textOverflow: 'ellipsis', overflow: 'hidden', height: 40, margin: '0 2px'}}>
							{item.record['title'] || 'No Title'} <br/>
						</Typography.Text>
						<Tag style={{fontSize: 10}}>{item.status}</Tag>
					</List.Item>
				)}
			/>
		</div>
	);
};

export default QueuedRecords;
