import React from "react";
import {Button, Input, List, Space, Tag, Tooltip} from "antd";
import FulcrumQuery from "../../../components/fulcrum-query";
import {ClearOutlined} from "@ant-design/icons";
import {useAppStore} from "../../../hooks/useApp.hook";
import FulcrumField from "../../../components/fulcrum-fields";

const FilterOptions = ({filter, selectValues = [], onChange}) => {
	const name = filter['data_name'];
	const [search, setSearch] = React.useState('');

	const {state: {app}} = useAppStore();

	const select = v => {
		if (selectValues.includes(v)) {
			onChange([...selectValues.filter(r => r !== v)])
		} else {
			onChange([...selectValues, v]);
		}
	};

	selectValues = typeof selectValues === 'string' ? ['Blank']  : selectValues;

	const DataList = ({data = [], loading, labels}) => (
		<List
			loading={loading}
			size={'small'}
			style={{height: 200, overflowY: 'auto'}}
			dataSource={data
				.filter((e, i) => {
					const label = labels && typeof labels[i] === 'string' ? labels[i] : null;
					if (search !== '') {
						if (e)
							if (typeof e === 'string')
								return (label || e).toLowerCase().includes(search.toLowerCase());
							else return e.includes(search);
						else return false;
					} else return true;
				})
			}
			renderItem={(item, i) => {
				const label = labels && typeof labels[i] === 'string' ? labels[i] : null
				return (
					<List.Item
						style={{cursor: 'pointer', backgroundColor: selectValues.includes(item) ? '#DDD' : '#EEE'}}
						onClick={() => select(item)}>
						<FulcrumField
							field={filter}
							value={(label || item)}
							noStyle
							style={{fontSize: 12}}/>
					</List.Item>
				)
			}}
		>
			{!loading && (
				<List.Item
					style={{fontSize: 12, cursor: 'pointer', backgroundColor: selectValues.includes('Blank') ? '#DDD' : '#EEE'}}
					onClick={() => onChange(' is null')}>
					Blank
				</List.Item>
			)}
		</List>
	);

	const getDataList = () => {
		return (
			<FulcrumQuery defaultQuery={{
				select: [`distinct(${name})`],
				sort: {field: name, order: 'asc'},
				filters: {...(app.defaultQuery ? app.defaultQuery.filters : {})}
			}}>
				{({data, loading}) => {
					data = data && data.map(e => e[name]).filter(e => !!e);
					return (
						<DataList data={data || []} loading={loading}/>
					);
				}}
			</FulcrumQuery>
		);
	};

	return (
		<Space direction={'vertical'}>
			<div style={{padding: 2}}>
				{selectValues.map(key => (
					<Tag style={{margin: 1}} closable onClose={() => select(key)} key={key}>"{key}"</Tag>
				))}
			</div>
			<div style={{width: '100%'}}>
				<Input.Search style={{width: 'calc(100% - 35px)'}} placeholder={`Filter ${filter.label}`} onChange={e => setSearch(e.target.value)} allowClear/>
				<Tooltip title={'Clear Selected ' + filter.label} placement={'left'}>
					<Button
						icon={<ClearOutlined />}
						onClick={() => onChange([])}
						disabled={selectValues.length === 0}
					/>
				</Tooltip>
			</div>
			{getDataList()}
		</Space>
	)
};

export default FilterOptions;
