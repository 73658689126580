import React from "react";
import {Button, Layout, Space} from "antd";
import {Redirect} from 'react-router-dom';
import DashboardSider from "./components/sider";
import SplitPane from "react-split-pane";
import ColSorter from "./components/col-sorter";
import WindowControls from "./components/window-controls";
import Loading from "../../components/page-loader";
import {ReloadOutlined} from "@ant-design/icons";
import RecordCount from "./components/record-count";
import {useAuthStore} from "../../hooks/useAuth.hook";
import {useAppStore} from "../../hooks/useApp.hook";
import TablePane from "./panes/table";
import MapPane from "./panes/map";
import FilterMenu from "./components/filter-menu";
import PreviewRecord from "./components/preview-record";
import useQueryHook from "../../hooks/useQuery.hook";
import ExportData from "./components/export-data";

const Content = () => {
	let s = localStorage.getItem('split-pane-size');
	s = parseInt(s) || window.innerHeight / 2;
	const {getData, query, records,loading,} = useQueryHook();
	const [size, setSize] = React.useState(s);
	const {state: {auth}} = useAuthStore();


	const changeSize = s => {
		setSize(s);
		localStorage.setItem('split-pane-size', s);
		if (s >= (window.innerHeight - 60))
			getData({bounds: null});
	};

	return (
		<Layout>
			<DashboardSider getData={getData} query={query} />
			<Layout.Content style={{width: `calc(100vw - 300px)`}}>
				<SplitPane
					key={'content'}
					style={{height: '100vh', position: 'relative'}}
					split={'horizontal'}
					size={size}
					maxSize={window.innerHeight - 45}
					minSize={45}
					primary="second"
					onChange={changeSize}
				>
					<MapPane
						height={window.innerHeight - size}
						records={records}
						setSize={changeSize}
						query={query}
						onChange={(bounds) => getData({bounds})}
					/>
					<TablePane
						height={size}
						records={records}
						loading={loading}
						onTable={() => getData({bounds: null})}
						onSort={res => {
							getData({sort: res});
						}}
					/>
				</SplitPane>
				<Space key={'controls'} align={'center'} style={{position: "absolute", top: 5, right: 5, fontSize: 18, zIndex: 5}}>
					<RecordCount count={records.length} query={query} loading={loading} />
					<Button loading={loading} icon={<ReloadOutlined />} onClick={() => getData(query)} />
					<ColSorter  />
					<FilterMenu
						token={auth && auth.token}
						getData={res => getData({filters: {...(res || {})}})}
						query={query && query.filters}
					/>
					<ExportData records={records} />
					<WindowControls setSize={changeSize} size={size} />
					<PreviewRecord />
				</Space>
			</Layout.Content>
		</Layout>
	)
};

const DashboardPage = () => {
	const {state: {isAuthenticated, auth, user, ...authState}} = useAuthStore();
	const {state: {app, form, fields, ...appState}} = useAppStore();

	return !isAuthenticated ?
		<Redirect to={'/login'} /> :
		(
			<Layout style={{height: '100vh'}}>
				{
					(authState.loading || appState.loading) ?
					<Loading /> :
					(!!app && <Content />)
				}
			</Layout>
		);
};

export default DashboardPage;


