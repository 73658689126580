import React from "react";
import Loading from "../components/page-loader";
import {Switch, Route} from "react-router-dom";
import DashboardPage from "./dashboard";
import LoginPage from "./login.page";
import {useAuthStore} from "../hooks/useAuth.hook";
import {useAppStore} from "../hooks/useApp.hook";
import {getApps} from "../config";
import {Button, List, Modal} from "antd";
import {ArrowRightOutlined, LogoutOutlined} from "@ant-design/icons";
import ImagePage from "./image";

const Pages = () => {
	const {state: {loading, user, auth}, actions: {reAuthenticate, logout}} = useAuthStore();
	const {actions: {setApp}, state: {app, ...appState}} = useAppStore();

	React.useEffect(() => {
		if (localStorage.getItem('auth')) {
			reAuthenticate()
				.then(res => {
					if (res) {
						const currentApp = localStorage.getItem('app');
						if (currentApp) {
							let apps = getApps(res.auth);
							apps = apps.filter(app => app.id === parseInt(currentApp))[0];
							setApp(apps, res);
						}
					}
				});
		}
	}, []);

	return loading ? <Loading /> : (
		<>
			<Switch>
				<Route path={'/login'} component={LoginPage} />
				<Route path={'/'} component={DashboardPage} />
			</Switch>
			{(!!user && !!auth && !app) && (
				(
					<Modal title={'Select App'} visible={!app && !appState.loading} footer={null}>
						<List
							dataSource={getApps(auth)}
							renderItem={(item) => (
								<List.Item
									extra={
										<Button
											onClick={() => setApp(item, {user, auth, reload: !!app})}
											icon={<ArrowRightOutlined />}/>
									}>
									<List.Item.Meta
										title={item.name}
									/>
								</List.Item>
							)}
						/>
						<Button onClick={() => logout()} icon={<LogoutOutlined />} size={'small'} danger>
							Exit
						</Button>
					</Modal>
				)
			)}
		</>
	);
};

export default () => (
	<Switch>
		<Route path={'/images'} component={ImagePage} />
		<Route path={"*"} component={Pages} />
	</Switch>
);
