import React from "react";
import {MenuOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from "array-move";
import {Button, Checkbox, Divider, Drawer, Empty, Input, Space, Tooltip} from "antd";
import {useAppStore} from "../../../hooks/useApp.hook";

const FieldItem = ({value, icon, onView}) => (
	<div style={{padding: 6, display: 'flex'}}>
		<span style={{marginRight: 10}}>
			<Tooltip title={value.hidden ? 'Show Field' : 'Hide Field'} placement={'left'}>
				<Checkbox
					style={{position: 'relative', zIndex: 10}}
					size={'small'}
					onChange={() => onView(value['data_name'])}
					checked={!value.hidden} />
			</Tooltip>
		</span>

		<div style={{cursor: 'pointer', color: 'black !important', padding: 2}}>
			{icon && (
				<span style={{marginRight: 10}}>
				{icon}
			</span>
			)}
			{value['label']}
		</div>

	</div>
);

const SortableList = SortableContainer(({items, onVisibleChange}) => {

	const SortableItem = SortableElement(FieldItem);

	const makeVisible = key => onVisibleChange(items.map(ite => ({
			...ite,
			hidden: ite['data_name'] === key ? !ite.hidden : ite.hidden
		})));

	return (
		<div>
			{items.map((value, index) => {
				return (
					<SortableItem
						key={index}
						onView={makeVisible}
						index={index}
						value={value}
						icon={<MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />} />
				);
			})}
		</div>
	);
});

const SortableComponent = ({items, onChange}) => {
	const [txt, setTxt] = React.useState(null);

	return (
		<div style={{display: 'grid', margin: 0}}>
			<Input.Search
				placeholder={'Search Field'}
				style={{width: 250, margin: '6px auto'}}
				onChange={e => setTxt(e.target.value !== "" ? e.target.value : null)}
			/>
			<Space style={{margin: '0 auto'}}>
				<Button block size={'small'} onClick={() => onChange(items.map(ite => ({...ite, hidden: true})))}>Hide All Fields</Button>
				<Button block size={'small'} onClick={() => onChange(items.map(ite => ({...ite, hidden: false})))}>Show All Fields</Button>
			</Space>
			<Divider style={{margin: '10px auto'}} />
			<div id={'sr-c'} style={{overflowY: 'auto', maxHeight: 'calc(100vh - 145px)'}}>
				<SortableList
					axis={'y'}
					pressThreshold={1}
					helperContainer={() => document.getElementById('sr-c')}
					lockAxis={"y"}
					lockToContainerEdges
					getContainer={() => document.getElementById('sr-c')}
					items={!txt ? items : items.filter(ite => ite.label.toLowerCase().includes(txt.toLowerCase()))}
					onVisibleChange={(res) => onChange(res)}
					onSortEnd={({oldIndex, newIndex}) => onChange(arrayMove(items, oldIndex, newIndex))}
				/>
			</div>
		</div>
	);
};

const ColSorter = () => {
	const {state: {fields}, actions: {setFields}} = useAppStore();
	const [show, setShow] = React.useState(false);

	return (
		<>
		<Tooltip title={'Change column order.'} placement="bottom">
			<Button
				onClick={() => setShow(true)}
				icon={<UnorderedListOutlined/>}
			/>
		</Tooltip>
		<Drawer
			destroyOnClose
			bodyStyle={{padding: 0}}
			width={300} visible={show} onClose={() => setShow(false)} title={'Sort Columns'}>
			{fields.length > 0 ? <SortableComponent items={[...fields]} onChange={setFields} /> : <Empty />}
		</Drawer>
		</>
	);
};

export default ColSorter;
