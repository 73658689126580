import React from "react";
import {
	Button,
	Descriptions,
	Divider,
	Drawer,
	Empty,
	Form,
	Input,
	Modal,
	Select, Space,
	Tooltip,
	Typography
} from "antd";
import {EditOutlined, UploadOutlined} from "@ant-design/icons";
import {useRecordStore} from "../../../hooks/useRecords.hook";
import {useAppStore} from "../../../hooks/useApp.hook";
import {ChoiceListDataSelect, DateChooser} from "../../../helpers";
import FulcrumForm from "../../../components/fulcrum-form";
import { UnsupportedFieldTypes } from "../../../config";

const UpdateRecords = () => {
	const {
		state: {
			selectedRecords, app
		},
		actions: {
			updateSelectedRecords
		}
	} = useRecordStore();
	const {state} = useAppStore();

	const [show, setShow] = React.useState(false);
	const [fields, setFields] = React.useState([]);
	const [update, setUpdate] = React.useState({});

	const updateRecords = (data) => {
		Modal.confirm({
			type: 'confirm',
			onOk: () => {
				updateSelectedRecords({
					records: selectedRecords,
					data
				});
				setShow(false);
				setFields([]);
			},
			title: `Please confirm these changes`,
			content: (
				<Descriptions column={1}>
					<Descriptions.Item label={'Record Count'}>
						{selectedRecords.length}
					</Descriptions.Item>
					{fields.map(f => (
						<Descriptions.Item key={f.key || f.data_name} label={f.label}>
							{data[f.key || f.data_name]}
						</Descriptions.Item>
					))}
				</Descriptions>
			)
		})
	};

	const close = () => {
		setShow(false);
		fields.length > 0 && setFields([]);
		setUpdate({});
	};

	return (
		<>
			<Tooltip title={'Update Selected Records'}>
				<Button size={'small'} icon={<UploadOutlined />} disabled={selectedRecords.length === 0} onClick={() => setShow(true)}>
					Update
				</Button>
			</Tooltip>
			<Drawer
				footer={(
					<Space>
						<Button key={'cancel'} onClick={close}>
							Cancel
						</Button>
						<Button key={'save'} onClick={() => updateRecords(update)} disabled={Object.keys(update).length === 0}>
							Update Records
						</Button>
					</Space>
				)}
				title={'Update Records'}
				onClose={close}
				visible={show}
				width={300}
				destroyOnClose
			>
				<Typography.Title level={5}>
					{selectedRecords.length} Record(s) are selected
				</Typography.Title>
				<Divider>Select Fields</Divider>
				<Select
					mode="multiple"
					allowClear
					style={{ width: '100%' }}
					placeholder="Select fields to update."
					value={fields.map(f => f['data_name'])}
					onChange={(keys, res) => setFields(res.map(r => r.info))}
				>
					{state.fields
						.filter(f => f.label !== 'Title')
						.filter(field => state.app.updateFields ? state.app.updateFields.includes(field['data_name']) : true)
						.filter(field => ![...UnsupportedFieldTypes, 'CalculatedField'].includes(field.type))
						.map(col => (
						<Select.Option value={col['data_name']} key={col['data_name']} info={col}>
							{col.label}
						</Select.Option>
					))}
				</Select>
				<Divider>Update Selection Form</Divider>
				{fields.length > 0  ? (
					<FulcrumForm
						app={app}
						filterFieldValues={state.app.filterFieldValues}
						keyField={'key'}
						edit={true}
						elements={fields}
						onValuesChange={(r, res) => setUpdate(res)}
					/>
				) : <Empty description={'Please select fields from list above'} />}
			</Drawer>
		</>
	);
};

export default UpdateRecords;
