import {Button, Result} from "antd";
import React from "react";


export default function NotFoundPage({history, message}) {
    return (
        <Result
            style={{margin: "50px auto"}}
            status={404}
            title={message}
            extra={<Button onClick={() => history.push("/")}>Go Home</Button>}
        />
    )
}