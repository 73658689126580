import React from "react";
import {Card} from "antd";
import FulcrumQuery from "../../../components/fulcrum-query";


const RecordCount = ({query, count, loading}) => {

	const [currentCount, setCurrentCount] = React.useState(count);

	query = {select: ['count(*)'], ...(query || {})};

	delete query.sort;
	// return null;
	return loading ? null : (
		<Card size={'small'} bodyStyle={{padding: 4, border: '1px solid rgba(0,0,0,0.2)'}}>
		<FulcrumQuery defaultQuery={query}>
			{({data, getData}) => {
				const total = (data && data[0] && data[0].count) || 0;

				if (currentCount !== count) {
					setCurrentCount(count);
					getData(query);
				}

				return (
					currentCount === total ? `Total Records: ${currentCount}` : `Showing ${currentCount} / ${total} Records`
				);
			}}
		</FulcrumQuery>
		</Card>
	);
};

export default RecordCount;
