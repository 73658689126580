
// TODO: add empty value filter.
// TODO: "hole_ref_" extra field to paste numbers for filter.

export const UnsupportedFieldTypes = [
	'RecordLinkField',
	'Repeatable',
	'AttachmentField',
	'VideoField',
];

export const FilterFieldTypes = [
	'DateTimeField',
	'TimeField',
	'TextField',
	'ClassificationField',
	'ChoiceField',
	'YesNoField',
	'CalculatedField'
];

const colors = {
	New: 'blue',
	Hold: 'pink',
	'HoldLPP': 'pink',
	'OriginalCondensed': 'red',
	Dispatched: 'Yellow',
	'OutofTolerance': '#681a1a',
	'Invoice': '#228B22',
	'InvoiceNeedsRevision': '#228B22',
	Complete: '#90ee90',
	'RedoPoorQuality': 'orange',
	'RedoPayment': 'orange',
	'InProgress': 'orange',
	'M&SLandscaping': 'red',
	"ValidatedHold": "#B3B3B3",
	"NoWork": '#90ee90'
};

const restrictAccess = (data) => {
	return ({fields, auth: {org: {role}}}) => {
		const roles = fields.filter(f => f.data_name === 'contractor_performing_restoration')[0].choices;
		return roles.map(c => c.value).includes(role.name) ?
			data : null;
	};
};

const onRecordUpdate = (record) => {
	const holeStatuses = record['form_values']['f660'].map(hole =>
		hole['form_values']['6818'] && hole['form_values']['6818']['choice_values'][0]);
	if (holeStatuses.includes('Original - Condensed')) {
		record['status'] = 'Original - Condensed';
	}
	if (holeStatuses.includes('Complete')) {
		record['status'] = 'Complete';
	}
	if (holeStatuses.includes('No Work')) {
		record['status'] = 'Complete';
	}
	if (holeStatuses.includes('Invoice Needs Revision') || holeStatuses.includes('Invoice')) {
		record['status'] = 'All Invoiced';
	}
	if (holeStatuses.includes('Redo Poor Quality') || holeStatuses.includes('Redo Payment')) {
		record['status'] = 'Redo';
	}
	if (holeStatuses.includes('Dispatched') || holeStatuses.includes('In Progress')) {
		record['status'] = 'Dispatched';
	}
	if (holeStatuses.includes('Out of Tolerance')) {
		record['status'] = 'Out of Tolerance';
	}
	if (holeStatuses.includes('Validated Hold')) {
		record['status'] = 'Validated Hold';
	}
	if (holeStatuses.includes('Hold') || holeStatuses.includes('Hold - LPP')) {
		record['status'] = 'Hold';
	}
	if (holeStatuses.includes('New')) {
		record['status'] = 'New';
	}
	if (holeStatuses.length > 0 && holeStatuses.every(status => status === 'M&S Landscaping')) {
		record['status'] = 'M&S Landscaping';
	}
	return record;
};

export const apps = [
	{
		id: 0,
		org: '4362087f-365c-4faa-9930-1127ed9ec82d',
		name: 'UNY Restoration Holes',
		formId: 'a7c39899-b490-4a0b-94a5-c49c763bf8d3',
		subFormField: 'restoration_holes',
		isContractor: restrictAccess(true),
		onRecordUpdate,

		defaultQuery: ({fields, auth: {org: {role}}}) => {
			const field = fields.filter(f => f.data_name === 'contractor_performing_restoration')[0];
			if (field && field.choices.map(c => c.value).includes(role.name.toUpperCase()))
				return {
					filters: {
						'lower(contractor_performing_restoration)': `like '%${role.name.toLowerCase()}%'`,
						restoration_status: 'not in (\'New\',\'Hold\',\'Hold - LPP\',\'Original - Condensed\',\'M&S Landscaping\', \'Validated Hold\', \'No Work\')',
					}
				};
			else
				return {};
		},

		formatRecords: ({records}) => {
			return records.map(rec => {
				rec['color'] = rec['restoration_status'] ?
					colors[rec['restoration_status'].replace(/-/g, '').replace(/ /g, '')] :
					'#999';
				rec['title'] = `${rec['restoration_status']} ${rec['hole_ref_'] || ''} ${rec['inspector_hole_locations'] || ''}`;
				return rec;
			});
		},

		fieldDisableConditions: restrictAccess({
			'oot_date': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'out_of_tolerance_length': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'out_of_tolerance_width': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'out_of_tolerance_depth': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'out_of_tolerance_sq_ft': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'oot_asphalt_w_cutback_length': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'oot_asphalt_w_cutback_width': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'out_of_tolerance_photo': vals => vals ? vals['oot_status'] === 'Approved' : false,
			'*': vals => vals ? vals['restoration_status'].toLowerCase() === "complete" : false,
		}),

		filterFieldValues: restrictAccess({
			restoration_status: ['New', 'Hold', 'Hold - LPP', 'Original - Condensed', 'M&S Landscaping', 'Complete', 'No Work', 'Validated Hold'],
		}),

		hideFields: restrictAccess([
			'main_verification_hole',
			'post_inspector_length',
			'post_inspector_width',
			'reason_for_hold',
			'pre_measurements',
			'construction_measurements',
			'oot_measurement',
			'invoice_measurement',
			'post_measurement',
			'hole_materials',
			'restoration_hole_locations',
			'riser_sleeve_visible',
			'h_length',
			'h_width',
			'h_depth',
			'depth_16',
			'hole_comments',
			'calculated_sq_footage'
		]),

		updateFields: restrictAccess([
			'restoration_status',
			'dig_safe_ticket_no',
			'dig_safe_clear',
			'oot_date',
			'out_of_tolerance_length',
			'out_of_tolerance_width',
			'out_of_tolerance_depth',
			'out_of_tolerance_sq_ft',
			'oot_asphalt_w_cutback_length',
			'oot_asphalt_w_cutback_width',
			'out_of_tolerance_photo',
			'restoration_completed_date',
			'invoice',
			'invoice_comments',
			'excavation_sq_ft_billed',
			'unit_billed_1',
			'asphalt_sq_ft_billed',
			'unit_billed_2',
			'quantity_3',
			'unit_billed_3',
			'quantity_4',
			'unit_billed_4',
			'quantity_5',
			'unit_billed_5',
			'material_ticket',
			'attachments',
		])

	},
	// {
	// 	id: 3,
	// 	test: true,
	// 	org: '4362087f-365c-4faa-9930-1127ed9ec82d',
	// 	name: 'UNY Restoration Holes Test',
	// 	formId: '2e517e4f-1d69-4895-9209-f445145e151b',
	// 	subFormField: 'restoration_holes',
	// 	onRecordUpdate,
	// 	defaultQuery: ({fields, auth: {org: {role}}}) => {
	// 		const field = fields.filter(f => f.data_name === 'contractor_performing_restoration')[0];
	// 		if (field && field.choices.map(c => c.value).includes(role.name))
	// 			return {
	// 				filters: {
	// 					contractor_performing_restoration: [role.name],
	// 					restoration_status: 'not in (\'New\',\'Hold\',\'Hold - LPP\',\'Original - Condensed\',\'M&S Landscaping\')',
	// 				}
	// 			};
	// 		else
	// 		return {};
	// 	},
	// 	formatRecords: ({records}) => {
	// 		return records.map(rec => {
	// 			rec['color'] = rec['restoration_status'] ?
	// 				colors[rec['restoration_status'].replace(/-/g, '').replace(/ /g, '')] :
	// 				'#999';
	// 			rec['title'] = `${rec['restoration_status']} ${rec['hole_ref_'] || ''} ${rec['inspector_hole_locations'] || ''}`;
	// 			return rec;
	// 		});
	// 	},
	// 	filterFieldValues: restrictAccess({
	// 		restoration_status: ['New', 'Hold', 'Hold - LPP', 'Original - Condensed', 'M&S Landscaping']
	// 	}),
	// 	fieldDisableConditions: restrictAccess({
	// 		'oot_date': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'out_of_tolerance_length': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'out_of_tolerance_width': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'out_of_tolerance_depth': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'out_of_tolerance_sq_ft': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'oot_asphalt_w_cutback_length': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'oot_asphalt_w_cutback_width': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 		'out_of_tolerance_photo': vals => vals ? vals['oot_status'] === 'Approved' : false,
	// 	}, {}),
	// 	hideFields: restrictAccess([
	// 		'main_verification_hole',
	// 		'post_inspector_length',
	// 		'post_inspector_width',
	// 		'reason_for_hold',
	// 		'pre_measurements',
	// 		'construction_measurements',
	// 		'oot_measurement',
	// 		'invoice_measurement',
	// 		'post_measurement',
	// 		'hole_materials',
	// 		'restoration_hole_locations',
	// 		'riser_sleeve_visible',
	// 		'h_length',
	// 		'h_width',
	// 		'h_depth',
	// 		'depth_13',
	// 		'hole_comments',
	// 		'calculated_sq_footage'
	// 	]),
	// 	updateFields: restrictAccess([
	// 		'restoration_status',
	// 		'dig_safe_ticket_no',
	// 		'dig_safe_clear',
	// 		'oot_date',
	// 		'out_of_tolerance_length',
	// 		'out_of_tolerance_width',
	// 		'out_of_tolerance_depth',
	// 		'out_of_tolerance_sq_ft',
	// 		'oot_asphalt_w_cutback_length',
	// 		'oot_asphalt_w_cutback_width',
	// 		'out_of_tolerance_photo',
	// 		'restoration_completed_date',
	// 		'invoice',
	// 		'invoice_comments',
	// 		'excavation_sq_ft_billed',
	// 		'unit_billed_1',
	// 		'asphalt_sq_ft_billed',
	// 		'unit_billed_2',
	// 		'quantity_3',
	// 		'unit_billed_3',
	// 		'quantity_4',
	// 		'unit_billed_4',
	// 		'quantity_5',
	// 		'unit_billed_5',
	// 		'material_ticket',
	// 		'attachments',
	// 	])
	// },
	{
		id: 1,
		restricted: true,
		org: '4362087f-365c-4faa-9930-1127ed9ec82d',
		name: 'UNY Restoration Main (Parent)',
		formId: 'a7c39899-b490-4a0b-94a5-c49c763bf8d3',
		defaultQuery: {}
	},
	// {
	// 	id: 2,
	// 	test: true,
	// 	restricted: true,
	// 	org: '4362087f-365c-4faa-9930-1127ed9ec82d',
	// 	name: 'UNY Restoration Main Test',
	// 	formId: '2e517e4f-1d69-4895-9209-f445145e151b',
	// }
];

export const getApps = (auth) => {
	const isAdmin = auth && auth.isAdmin;
	const isOwner = auth && auth.isOwner;
	return (isAdmin || isOwner) ? apps : apps
		.filter(a => a.restricted !== true)
		.filter(a => a.test !== true && isOwner !== true)
}

/*
calculated field dates sw Brian

"gas main job AT" > located in diff app > resto projects sw Brian

keyword search -> Brian
*/
