import React, {createContext, useContext, useReducer} from "react";
import {useAppStore} from "./useApp.hook";
import {useAuthStore} from "./useAuth.hook";
import uuid from "react-uuid";
import Queue from 'queue';
import {Client} from 'fulcrum-app';
import {message} from "antd";
import {formatUpdateData} from "../helpers";

const queue = Queue({
	autostart: true,
	concurrency: 1,
	timeout: 5000
});

const queueState = {
	queued: 'queued',
	updating: 'updating',
	completed: 'completed',
	failed: 'failed',
	timedOut: 'timout',
};

const initState = () => ({
	selectedRecords: [],
	queuedRecords: [],
	isUpdating: false,
	loading: false,
	showSelectedRecords: false,
	preview: null
});

const actions = {
	loading: 'loading_records',
	set_records: 'set_sel_records',
	set_queued_records: 'set_q_records',
	reset: 'reset',
	set_preview: 'set_preview',
};

const reducer = (state = initState(), action) => {
	switch (action.type) {
		case actions.loading:
			return {
				...state,
				loading: action.loading
			};
		case actions.reset:
			return initState();
		case actions.set_records:
			return {
				...state,
				selectedRecords: action.records
			};
		case actions.set_preview:
			return {
				...state,
				preview: action.preview
			};
		case actions.set_queued_records:
			return {
				...state,
				queuedRecords: action.records
			};
		default:
			return state;
	}
};

const StoreContext = createContext(null);

let queuedRecords = [];

export const RecordStoreProvider = ({children}) => {
	const [state, dispatch] = useReducer(reducer, null, initState);
	const {state: {fields, app, form}} = useAppStore();
	const {state: {user, auth}} = useAuthStore();
	const [activeRecord, setActiveRecord] = React.useState(null);

	const client = auth && new Client(auth.token);

	const updateQueueItem = rec => {
		queuedRecords = queuedRecords.map(res => res.id === rec.id ? rec : res);
		setQueuedRecords(queuedRecords);
	};

	const setPreview = preview => dispatch({
		type: actions.set_preview,
		preview
	});

	const onUpdate = React.useCallback((result) => {
		result.status = queueState.completed;
		updateQueueItem(result);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setQueuedRecords = records => dispatch({type: actions.set_queued_records, records});

	const setSelectedRecords = records => dispatch({type: actions.set_records, records});

	const reset = () => {
		setQueuedRecords([]);
		queuedRecords = [];
	};

	React.useEffect(() => {
		const end = () => {
			message.success('Records successfully updated', 5)
				.then(() => reset());
		};
		queue.on('success', onUpdate);
		queue.on('failed', onUpdate);
		queue.on('end', end);
		return () => {
			queue.off('success', onUpdate);
			queue.off('failed', onUpdate);
			queue.off('end', end);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onUpdate]);

	const formatRecordsForQueue = ({records, data}) =>
		records.map(record => ({record, data, fields, app, user, id: uuid(), status: queueState.queued, form}));

	const updateRecord = async (record, data) => {
		const recordId = !!app.subForm ? record['_parent_id'] : record['_record_id'];
		update(recordId, data, {fieldKey: 'data_name', record})
			.then(() => message.success('Record updated successfully.'))
			.catch(e => message.error('Failed to update record', e.message));
	};

	const update = async (id, data, options = {fieldKey: 'key', record: {}}) => {
		let fulcrumRecord = await client.records.find(id);
		if (!!fulcrumRecord) {
			fulcrumRecord = formatUpdateData({
				data, fields,
				record: fulcrumRecord,
				repeatable: app.subForm,
				fieldKey: options.fieldKey,
				id: options.record.id
			});
			fulcrumRecord = app.onRecordUpdate ? app.onRecordUpdate(fulcrumRecord, app) : fulcrumRecord;
			return client.records.update(id, fulcrumRecord);
		}
	};

	const patchFulcrumRecord = (qr) => {
		return () => {
			return new Promise(async (resolve, reject) => {
				qr.status = queueState.updating;
				updateQueueItem(qr);
				const {data, record, app} = qr;
				const recordId = !!app.subForm ? record['_parent_id'] : record['_record_id'];
				update(recordId, data, {fieldKey: 'key', record})
					.then(() => setTimeout(() => resolve(qr), 500))
					.catch(e => {
						message.error(e.message);
						setTimeout(() => reject(qr), 500);
					});
			});
		}
	};

	const updateSelectedRecords = (data) => {
		const queueRecords = formatRecordsForQueue(data);
		setSelectedRecords([]);
		queuedRecords = [...queuedRecords, ...queueRecords];
		setQueuedRecords(queuedRecords);
		setTimeout(() => {
			for (let i in queueRecords) {
				queue.push(patchFulcrumRecord(queueRecords[i]));
			}
		}, 100);
	};

	return (
		<StoreContext.Provider
			value={{
				state: {
					...state,
					activeRecord
				},
				actions: {
					setSelectedRecords,
					updateSelectedRecords,
					setPreview,
					updateRecord,
					setActiveRecord
				}
			}}>
			{children}
		</StoreContext.Provider>
	);
};

export const useRecordStore = () => useContext(StoreContext);
