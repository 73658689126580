import React from "react";

const Source = ({map, name, data, children}) => {
	const [ready, setReady] = React.useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		if (!map.getSource(name))
			map.addSource(name, {
				type: 'geojson',
				data,
				// cluster: data.features.length > 2000,
				// clusterMaxZoom: 10,
				// clusterRadius: 50
			});
		else {
			map.getSource(name).setData(data);
		}
		if (ready === false) setReady(true);
	});

	children = children && React.Children.toArray(children).map(child => React.cloneElement(child, {
		map,
		source: name
	}));

	return (ready && children) || null;
};

export default Source;
