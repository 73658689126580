import {createContext, useContext, useReducer} from "react";
import {Client, createAuthorization, getUser} from "fulcrum-app";

const initState = () => ({
	isAuthenticated: false,
	loading: !!localStorage.getItem('auth'),
	user: null,
	auth: null,
});

const actions = {
	loading: 'set_auth_loading',
	set_user: 'set_user',
	set_auth: 'set_auth',
	logout: 'logout',
};

const reducer = (state = initState(), action) => {
	switch (action.type) {
		case actions.loading:
			return {
				...state,
				loading: action.loading
			};
		case actions.set_user:
			return {
				...state,
				user: action.user
			};
		case actions.set_auth:
			return {
				...state,
				auth: action.auth,
				isAuthenticated: true
			};
		case actions.logout:
			return {
				...initState(),
			};
		default:
			return state;
	}
};

const StoreContext = createContext(null);

export const AuthStoreProvider = ({children}) => {
	const [state, dispatch] = useReducer(reducer, null, initState);

	const setLoading = loading => ({
		type: actions.loading,
		loading
	});
	const setUser = user => ({type: actions.set_user, user});
	const setAuth = auth => ({type: actions.set_auth, auth});

	const login = ({email, password}) => {
		return getUser(email, password)
			.then(({contexts, current_organization, ...user}) => {
				user.orgs = contexts;
				localStorage.setItem('user', JSON.stringify(user));
				dispatch(setUser(user));
				return true;
			});
	};

	const reAuthenticate = async () => {
		try {
			dispatch(setLoading(true));
			let auth = JSON.parse(localStorage.getItem('auth'));
			let user = JSON.parse(localStorage.getItem('user'));
			const cli = new Client(auth.token);

			// tests if token valid
			const exists = await cli.authorizations.find(auth.id);

			if (!!exists && !!user && !!auth) {
				dispatch(setUser(user));
				dispatch(setAuth(auth));
				dispatch(setLoading(false));
				return {user, auth};
			} else {
				await logout();
				return null;
			}
		} catch (e) {
			await logout();
			return null;
		}
	};

	const setOrg = (data, {email, password, token}) => {
		dispatch(setLoading(true));
		return createAuthorization(
			email, password, data.id, 'Maestro',
			// 60 * 20 // EXP IN 20 Mins
			(12 * 60 * 60) // EXP IN 12 HRS
		).then(auth => {
			auth.org = data;
			auth.isAdmin = [
				'Owner', 'Restoration Supervisor'
			].includes(data.role.name);
			auth.isOwner = data.role.name === 'Owner';
			localStorage.setItem('auth', JSON.stringify(auth));
			dispatch({
				type: actions.set_auth,
				auth
			});
			dispatch(setLoading(false));
		}).catch(() => setLoading(false));
	};

	const logout = async () => {
		const {isAuthenticated, auth} = state;
		if (isAuthenticated) {
			try {
				// clean up token from fulcrum
				const client = new Client(auth.token);
				await client.authorizations.delete(auth.id);
			} catch (e) {
				console.log(e.message);
			}
		}
		localStorage.clear();
		dispatch({type: actions.logout});
		dispatch(setLoading(false));
		window.location.replace('/');
	};

	return (
		<StoreContext.Provider
			value={{
				state,
				actions: {
					logout,
					login,
					setOrg,
					reAuthenticate
				}
			}}>
			{children}
		</StoreContext.Provider>
	);
};

export const useAuthStore = () => useContext(StoreContext);
