import React from "react";
import {Client} from 'fulcrum-app';
import {message} from "antd";
import {useAppStore} from "../hooks/useApp.hook";
import {useAuthStore} from "../hooks/useAuth.hook";
import {generateQuery} from "../helpers";
import _ from 'lodash';


let qr = {};

const FulcrumQuery = ({children, defaultQuery, queryOnReady = true, debug = false}) => {
	const [data, setData] = React.useState([]);
	const [query, setQuery] = React.useState({});
	const [loading, setLoading] = React.useState(false);



	if (queryOnReady === false)
		qr = query;

	const {state: {app}} = useAppStore();
	const {state: {auth}} = useAuthStore();

	const tableName = app && `${app.formId}${app.subFormField ? '/' + app.subFormField : ''}`;
	const client = auth && new Client(auth.token);

	const _getData = (q) => {
		if (tableName && client) {
			setLoading(true);
			if (typeof q === 'object')
				q = generateQuery(tableName, q);
			if (debug === true) {
				console.log(q);
				setLoading(false);
			} else
			client.query(q)
				.then(res => {
					res = res['rows'];
					setData(res);
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					message.error('Failed to load data.').then();
				});
		}
	};

	const getData = React.useCallback(
		_.debounce(q => {
			if (typeof q === 'object' && queryOnReady === false)
				q = {
					...(defaultQuery || {}),
					...(qr || {}),
					...(q || {}),
					filters: q.filters || qr.filters || {},
				};
			if (defaultQuery)
				q.filters = q.filters && {
					...q.filters,
					...(defaultQuery.filters || {})
				}
			setQuery(q);
			_getData(q)
		}, 300)
		, [query]);

	React.useEffect(() => {
		defaultQuery && queryOnReady === true && getData(defaultQuery);
	}, []);

	children = children && typeof children === 'function' && children({
		getData,
		data,
		loading,
		query
	});
	return (children || null);
};


export default FulcrumQuery;

