import React from "react";
import MapComponent from "./map";
import Source from "./source";
import Layer from "./layer";
import turf from "turf";
import {useRecordStore} from "../../../../hooks/useRecords.hook";

let q = {};
let rcs = [];
const MapPane = ({height, records, onChange, query}) => {
	const [map, setMap] = React.useState(null);
	q = query;

	rcs = records;

	React.useEffect(() => {
		map && map.resize();
	});

	const {actions: {setSelectedRecords, setPreview, setActiveRecord}, state: {activeRecord}} = useRecordStore();

	const filterDataByShape = (shapes) => {
		let recs = [];

		const getRecs = shape => rcs.filter(res => {
			if (res['_geometry']) {
				return turf.inside(
					turf.point(res['_geometry']['coordinates']),
					turf.polygon(shape['geometry']['coordinates'])
				);
			} return false;
		});

		for (let i in shapes) {
			const shape = shapes[i];
			recs = [...recs, ...getRecs(shape)];
		}

		return recs;
	};

	const onReady = _map => {
		_map.resize();
		setMap(_map);
		const bounds = _map.getBounds();
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest();
		(height >= 60) && onMapChange({
			ne: [ne.lat, ne.lng],
			sw: [sw.lat, sw.lng],
		}, query);
	};

	const onMapChange = (bounds) => {
		onChange(bounds, q);
	};

	return (
		<>
			{height <= 50 && (
				<div style={{width: `calc(100vw - 220px)`, height: 50, backgroundColor: '#FFF', position: 'absolute', zIndex: 4, top: 0, right: 0, borderBottom: '4px solid #2B79C9'}} />
			)}
			<MapComponent
				onReady={(m) => onReady(m)}
				height={height}
				onBoundsChange={bounds => onMapChange(bounds)}
				onDrawComplete={function({features}) {
					if (features.length > 0) {
						setSelectedRecords(filterDataByShape(features, records))
					} else {
						setSelectedRecords([]);
					}
				}}>
				{records.length > 0 && (
					<Source
						name={'records'}
						data={{
							type: 'FeatureCollection',
							features: records.filter(res => !!res._geometry)
								.map(({_geometry, ...record}) => ({
								id: record['_record_id'],
								type: 'Feature',
								geometry: _geometry,
								properties: {...record, title: record['job_address']}
							}))
						}}
					>
						<Layer
							preview={activeRecord}
							name={'points'}
							onClick={(res) => setPreview(res)}
							onMouseOver={setActiveRecord}/>
					</Source>
				)}
			</MapComponent>
		</>
	);
};

export default MapPane;
