import React from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import FreehandMode from 'mapbox-gl-draw-freehand-mode'
import ReactResizeObserver from "rc-resize-observer";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = 'pk.eyJ1IjoicGFydGhqaGF2ZXJpMjA5IiwiYSI6ImNrNWZmeXFibzBmY28zZnBiMnR6N2ExZ3kifQ.ozl3EOcNiHuJ5huckTEeVw';

const MapComponent = ({onReady, height, children, onBoundsChange = () => {}, onDrawComplete = () => {}}) => {
	const [map, setMap] = React.useState(null);

	React.useEffect(() => {
		const zoom = localStorage.getItem('zoom') || 6;
		let cacheCent = localStorage.getItem('center');
		try {
			cacheCent = JSON.parse(cacheCent);
			cacheCent = [cacheCent.lng, cacheCent.lat];
		} catch (e) {
			cacheCent = null;
		}
		let center = cacheCent || [-74.5846457, 43.130506];
		const _map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/streets-v11',
			center,
			zoom
		});
		const geocoder = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl
		});

		const draw = new MapboxDraw({
			userProperties: true,
			displayControlsDefault: false,
			controls: {
				polygon: true,
				trash: true,
			},
			modes: Object.assign(MapboxDraw.modes, {
				draw_polygon: FreehandMode,
			})
		});

		_map.on('load', () => {
			setMap(_map);
			onReady && onReady(_map);
			_map.on('dragend', onMapMoveEnd);
			_map.on('zoomend', e => {
				onMapMoveEnd(e);
			});
			_map.addControl(geocoder, 'top-left');
			_map.addControl(new mapboxgl.NavigationControl(), 'top-left');
			_map.addControl(draw, 'bottom-right');
			_map.on('draw.update', () => onDrawComplete(draw.getAll()));
			_map.on('draw.create', () => onDrawComplete(draw.getAll()));
			_map.on('draw.delete', () => onDrawComplete(draw.getAll()));
		});
	}, []);

	const onMapMoveEnd = e => {
		const map = e.target;
		if (map) {
			const bounds = map.getBounds();
			const ne = bounds.getNorthEast();
			const sw = bounds.getSouthWest();
			const zoom = map.getZoom();
			const center = map.getCenter();

			localStorage.setItem('center', JSON.stringify( center));
			localStorage.setItem('zoom', JSON.stringify(zoom));

			onBoundsChange && onBoundsChange({
				ne: [ne.lat, ne.lng],
				sw: [sw.lat, sw.lng],
			});
		}
	};

	children = children && React.Children.toArray(children).map(child => React.cloneElement(child, {
		map,
	}));


	return (
		<ReactResizeObserver
			onResize={() => map && map.resize()}
		>
			<div id={'map'} style={{height: height, width: '100%'}}>
				{(map && children) || null}
			</div>
		</ReactResizeObserver>
	);
};

export default MapComponent;
