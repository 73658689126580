import QB from "pg-query-builder";


export const getMembers = client => client.memberships.all()
	.then(res => res.objects)
	.then(res => res.map(r => ({key: r.id, label: `${r['first_name']} ${r['last_name']} (${r.email})`})))
	.then(res => generateMaestroField({label: 'Assigned To', choices: res, data_name: '_assigned_to_id', key: '_assigned_to_id', mainKey: true}));

export const getProjects = client => client.projects.all()
	.then(res => res.objects.map(r => ({key: r['id'], label: r['name']})))
	.then(res => generateMaestroField({label: 'Projects', choices: res, data_name: '_project_id', key: '_project_id', mainKey: true}));

export const getStatuses = form => {
	return form ? generateMaestroField({
		label: 'Status',
		key: '_status',
		data_name: '_status',
		mainKey: true,
		custom: null,
		choices: form['status_field']['choices'].map(d => ({
			key: d['value'],
			label: d['label'],
			color: d['color']
		}))
	}) : [];
};

const generateMaestroField = (ps) => ({
	type: 'ChoiceField',
	custom: true,
	...ps
});

const genFilters = (q, filters, parent) => {
	const keys = Object.keys(filters);
	for (let i in keys) {
		if (keys[i] !== "child") {
			let field = keys[i]
			if (field.includes("lower")) {
				field = field.split("(")[1]
				field = field.substring(0, field.length - 1)
				field = `lower(${parent ? parent + "." : ""}"${field}")`
			} else field = `${parent ? parent + "." : ""}"${field}"`

			if (Array.isArray(filters[keys[i]]))
				q.where(`${field} in (${filters[keys[i]].map(r => `'${r}'`).toString()})`);
			else if (typeof filters[keys[i]] === 'string')
				q.where(`${field} ${filters[keys[i]]}`);
		}
	}
};

const genBounds = (q, {bounds: {ne, sw}, parent}) => {
	const round = v => v.toFixed(4);
	q.where(`${parent ? parent + "." : ""}"_longitude" BETWEEN ${round(sw[1])} and ${round(ne[1])}`);
	q.where(`${parent ? parent + "." : ""}"_latitude" BETWEEN ${round(sw[0])} and ${round(ne[0])}`);
};

const genSort = (q, {field, order, parent}) => {
	q.order(`${parent ? parent + "." : ""}${field} ${order}`);
};

const genSelect = (q, selects = []) => {
	for(let i in selects) {
		q.select(selects[i]);
	}
};


export const generateQuery = (tableName, q = {}) => {
	const qb = new QB();

	genSelect(qb, q.select ? q.select : ['parent.*']);

	qb.from(`only "${tableName}" as parent`);

	if (q?.filters?.["child"]) {
		qb.join(`JOIN "${tableName}/restoration_holes" as child on child._parent_id = parent._record_id`)
		if (q.filters["child"]) genFilters(qb, q.filters.child, "child")
	}

	if (q.filters) genFilters(qb, q.filters, "parent");

	if (q.bounds) genBounds(qb, {bounds: q.bounds, parent: "parent"});

	if (q.sort) genSort(qb, {...(q.sort || {}), parent: "parent"});

	qb.limit(q.limit || 5000);
	return qb.query().text;
};

export const formatUpdateData = ({id, data, fields, record, fieldKey, repeatable}) => {
	const res = {};
	const form_values = {};

	const keys = Object.keys(data);
	fields = fields.filter(r => keys.includes(r[fieldKey]));

	for (let i in fields) {
		const field = fields[i];
		if (field.mainKey === true) {
			res[field.key.replace('_', '')] = data[field[fieldKey]];
			continue;
		}
		switch (field.type) {
			case 'PhotoField':
				form_values[field.key] = data[field[fieldKey]].map(p => ({
					photo_id: p
				}));
				break;
			case 'ChoiceField':
				form_values[field.key] = {
					other_values: [],
					choice_values: Array.isArray(data[field[fieldKey]]) ?
						data[field[fieldKey]] :
						[data[field[fieldKey]]]
				};
				break;
			default:
				form_values[field.key] = data[field[fieldKey]];
				break;
		}
	}

	if (repeatable) {
		record['form_values'][repeatable.key] =
			record['form_values'][repeatable.key].map(res => id === res.id ? ({
				...res,
				form_values: {...res['form_values'], ...form_values}
			}) : res);
	} else {
		record = {
			...record,
			...res,
			form_values: {
				...record.form_values,
				...form_values
			}
		}
	}

	return record;
};

export const getColor = (status, form) => {
	let statuses = getStatuses(form) || [];
	statuses = statuses.choices || [];
	status = statuses.filter(f => f.label === status)[0];
	return status ? status.color : '#000';
};

export const formatRecordView = (records, selectedRecords, app, form) => {
	const idKey = app && app.subFormField ? '_child_record_id' : '_record_id';
	return records.map(item => ({
		...item,
		id: item[idKey],
		__is_selected: selectedRecords.map(r => r[idKey]).includes(item[idKey]),
		__is_selected_yn: selectedRecords.map(r => r[idKey]).includes(item[idKey]) ? 'y' : 'n',
		color: item['_status'] && getColor(item['_status'], app.form),
	}));
};
