import React from "react";
import {Button, Divider, Layout, Modal, Select, Space, Tooltip, Typography} from "antd";
import Logo from '../../../assets/maestro-m-white.png';
import LogoFull from '../../../assets/maestro-logo-white.png';
import {
	ExclamationOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import {useAuthStore} from "../../../hooks/useAuth.hook";
import {useAppStore} from "../../../hooks/useApp.hook";
import SelectedRecords from "./selected-records";
import QueuedRecords from "./queued-records";
import HoleRefSearch from "./hole-ref-search";
import {getApps} from "../../../config";

const DashboardSider = ({show, getData, query}) => {
	const {state: {user, auth}, actions: {logout}} = useAuthStore();
	const {state: {app}, actions: {setApp}} = useAppStore();

	const logoutCurrentUser = () => Modal.confirm({
		title: 'Are you sure you want to logout?',
		icon: <ExclamationOutlined />,
		okButtonProps: {
			danger: true,
			icon: <LogoutOutlined />
		},
		okText: 'Logout',
		onOk: logout,
	});
	const apps = getApps(auth);

	return (
		<Layout.Sider
			width={220}
			style={{overflow: 'hidden', padding: 10}}
		>
			<div style={{padding: 10, textAlign: 'center', marginBottom: 10}}>
				<img style={{maxWidth: '100%'}} src={!show ? LogoFull : Logo} alt={'Maestro by The Sequel Group'} />
			</div>

			<Space direction={'vertical'} style={{height: 'calc(100vh - 130px)', width: '100%', overflowY: 'auto', overflowX: 'hidden'}}>
				<Space style={{textAlign: 'center', width: '100%'}} direction={'vertical'}>
					<Space>
						<div className={'ant-card'} style={{width: 166, height: 25, overflow: 'hidden', padding: 1}}>
							<Typography.Text ellipsis style={{fontSize: 12, userSelect: 'none', textOverflow: 'ellipsis'}}>{user && user.email}</Typography.Text>
						</div>
						<Tooltip title={'Logout'}>
							<Button
								block
								danger
								size={'small'}
								icon={<LogoutOutlined />}
								onClick={logoutCurrentUser} />
						</Tooltip>
					</Space>
					<Select
						autoFocus={!app}
						size={'small'}
						placeholder={'Select App'}
						value={app && app.id}
						style={{width: '100%', fontSize: 11}}
						onChange={(i, res) => setApp(apps.filter(a => a['id'] === res.value)[0], {user, auth, reload: !!app})}>
						{auth ? apps.filter(app => app.org === auth.org.id).map(app => (
							<Select.Option style={{fontSize: 11}} key={app.id} value={app.id}>{app.name}</Select.Option>
						)) : null}
					</Select>
				</Space>
				{app && [
					<Divider key={0} style={{border: '1px solid rgba(255,255,255,0.2)', margin: 2}} />,
					<HoleRefSearch app={app} key={'1'}  getData={getData} query={query}/>,
					// (app.subFormField === 'restoration_holes' ? <HoleRefSearch key={'1'}  getData={getData} query={query}/> : null),
					<SelectedRecords key={'2'}/>,
					<QueuedRecords key={'3'}/>
				]}
			</Space>
			<div style={{textAlign: 'center', color: '#999', fontSize: 11}}>
				© {new Date().getFullYear()} The Sequel Group
			</div>
		</Layout.Sider>
	);
};

export default DashboardSider;
