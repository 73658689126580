import React from "react";
import {Button, Drawer, Empty, Input, Menu, Space, Tooltip} from "antd";
import {ClearOutlined, FilterOutlined} from "@ant-design/icons";
import {useAppStore} from "../../../hooks/useApp.hook";
import FilterOptions from "./filter-options";
import {FilterFieldTypes} from "../../../config";

const FilterMenu = ({token, getData, query}) => {
	const [show, setShow] = React.useState(false);
	const [search, setSearch] = React.useState('');
	const [selectedFilters, setSelectedFilters] = React.useState({});
	const [currentkey, setKey] = React.useState([]);
	const {state: {form, fields, app}} = useAppStore();

	const handleFilterChange = (vals, col) => {
		const c = selectedFilters;
		if (vals.length === 0) {
			delete c[col['data_name']]
			delete query[col['data_name']]
		} else {
			c[col['data_name']] = vals
		}
		setSelectedFilters({
			...c
		});
		getData && getData({...query, ...c});
	};

	return (
		<>
			<Space style={{maxHeight: 40, overflowY: 'visible'}}>
				{Object.keys(selectedFilters).length !== 0 && (
					<Tooltip title={'Clear All Filters'} placement={'bottom'}>
						<Button
							danger
							style={{margin: 0}}
							icon={<ClearOutlined/>}
							onClick={() => {
								setSelectedFilters({});
								getData({});
							}}
						/>
					</Tooltip>
				)}
				<Tooltip title={'Filters'} placement={'bottom'} >
					<Button style={{margin: 0}} icon={<FilterOutlined />} onClick={() => setShow(true)} />
				</Tooltip>
			</Space>
			<Drawer
				placement={'right'}
				width={300}
				footer={null}
				bodyStyle={{padding: 6}}
				title={'Filters'}
				visible={show}
				onClose={() => setShow(false)}>
				<Space style={{width: '100%'}} direction={'vertical'}>
					<div style={{width: '100%'}}>
						<Input.Search
							style={{width: 'calc(100% - 35px)'}}
							placeholder={`Search Filter Fields`}
							onChange={e => setSearch(e.target.value)}
							allowClear/>
						<Tooltip title={'Clear Selected Filters'} placement={'left'}>
							<Button
								icon={<ClearOutlined />}
								onClick={() => {
									setSelectedFilters({});
									getData({});
								}}
								disabled={Object.keys(selectedFilters).length === 0}
							/>
						</Tooltip>
					</div>

					<Menu mode={'inline'} style={{overflowY: 'auto', overflowX: 'hidden'}}>
						{fields.length === 0 && (
							<Menu.Item style={{height: 300}} disabled>
								<Empty />
							</Menu.Item>
						)}
						<Menu.Divider />
						<Menu.ItemGroup style={{maxHeight: 'calc(100vh - 120px)'}}>
						{fields.length > 0 && fields
							.filter(res => FilterFieldTypes.includes(res.type))
							.filter(e => (!!search && search !== '') ? e.label.toLowerCase().includes(search.toLowerCase()) : true)
							.map(col => [
								<Menu.Divider
									key={col['data_name'] + '-divider'} />,
								<Menu.SubMenu
									onTitleClick={({key}) => {
										if (currentkey.includes(key))
											setKey(currentkey.filter(k => k !== key));
										else {
											const k = currentkey;
											k.push(key);
											setKey([...k]);
										}
									}}
									title={col.label}
									key={col['data_name']}
									style={{backgroundColor: !!selectedFilters[col['data_name']] ? '#EEE' : '#FFF', padding: 0, width: '100%'}}>
									{currentkey.includes(col['data_name']) && (
										<FilterOptions
											token={token}
											filter={col}
											appId={form['id']}
											selectValues={selectedFilters[col['data_name']] || []}
											onChange={vals => handleFilterChange(vals, col)}
											defaultQuery={{...(query || {}), ...(app.defaultQuery || {})}}
										/>
									)}
								</Menu.SubMenu>
							])}
						</Menu.ItemGroup>
					</Menu>
				</Space>
			</Drawer>
		</>
	);
};

export default FilterMenu;
