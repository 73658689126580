import {formatRecordView, generateQuery} from "../helpers";
import {message} from "antd";
import React from "react";
import _ from "lodash";
import {useAuthStore} from "./useAuth.hook";
import {useAppStore} from "./useApp.hook";
import {useRecordStore} from "./useRecords.hook";
import qs from "qs"

let qr = {};
let controller
const useQueryHook = () => {
	const {state: {auth}} = useAuthStore();
	const {state: {app}} = useAppStore();
	const {state: {selectedRecords}} = useRecordStore();
	const [data, setData] = React.useState([]);
	const [query, setQuery] = React.useState({});
	const [loading, setLoading] = React.useState(false);

	qr = query;

	const _getData =  (q) => {
		controller = new AbortController()
		setLoading(true);
		q = generateQuery(app.tableName, q);
		const query = qs.stringify({
			q, format: "json"
		})
		fetch("https://api.fulcrumapp.com/api/v2/query?" + query, {
			headers: {
				"X-ApiToken": auth?.token,
				"Accept": "application/json",
				"Content-Type": "application/json",
				"User-Agent": "maestro-web",
			},
			signal: controller?.signal
		}).then(res => res.json())
		.then(res => {
			res = res['rows'] || [];
			setData(res);
			setLoading(false);
		})
		.catch(err => {
			setLoading(false);
			if (!err?.message?.includes('abort'))
				message.error('Failed to load data.').then();
			if (err.message && err.message.toLowerCase() === 'unathorized')
				window.location = '/';
		});
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getData = React.useCallback(
		_.debounce(q => {
			q = {
				...((app && app.defaultQuery) || {}),
				...(qr || {}),
				...(q || {}),
				filters: q.filters || qr.filters || {},
			};
			if (app && app.defaultQuery)
				q.filters = (q.filters ||  app.defaultQuery.filters) && {
					...((app && app.defaultQuery && app.defaultQuery.filters) || {}),
					...q.filters,
				};
			setQuery(q);
			controller?.abort?.()
			_getData(q)
		}, 500)
		, [ query ]);

	let records = formatRecordView(data, selectedRecords, app);
	records = !!(app && app.formatRecords) ? app.formatRecords({records}) : records;
	records = _.orderBy(records, ['__is_selected_yn'], ['desc']);

	return {
		getData,
		loading,
		records,
		query
	}
};

export default useQueryHook;

