import React from "react";
import {Drawer, Space, Button, BackTop, Modal} from "antd";
import {useRecordStore} from "../../../hooks/useRecords.hook";
import {useAppStore} from "../../../hooks/useApp.hook";
import {EditOutlined} from "@ant-design/icons";
import FulcrumForm from "../../../components/fulcrum-form";

const PreviewRecord = () => {
  const {state: {form, fields, app}} = useAppStore();
  const {state: {preview}, actions: {setPreview, updateRecord}} = useRecordStore();
  const [edit, setEdit] = React.useState(false);
  const [changes, setChanges] = React.useState({});

  const {elements} = app.subForm ? app.subForm : form;

  const _close = () => {
    _reset();
    setPreview(null);
  };
  const _reset = () => {
    setEdit(false);
    setChanges({});
  };

  const modChanges = (cb, save) => Modal.confirm({
    title: `${save ? 'Save' : 'Discard'} these changes?`,
    content: (
      <FulcrumForm
        app={app}
        edit={false}
        keyField={'data_name'}
        elements={fields
          .filter(elem => !elem.hidden && Object.keys(changes).includes(elem.data_name))}
        initialValues={changes}
        onValuesChange={(cgs) => setChanges({...changes, ...cgs})}
      />
    ),
    onOk: () => {
      if (save)
        updateRecord(preview, changes).then(_close);
      cb && cb()
    },
    okText: 'Yes',
    cancelText: 'No'
  });

  const cancelEditing = () => {
    if (Object.keys(changes).length > 0) {
      modChanges(_reset);
    } else _reset();
  };

  const close = () => {
    if (Object.keys(changes).length > 0) {
      modChanges(_close);
    } else _close();
  };

  return (
    <Drawer
      title={`${!edit ? 'Preview' : 'Edit'} Record`}
      footer={(
        <Space>
          {edit ? [
              <Button key={'cancel'} onClick={cancelEditing}>
                Cancel
              </Button>,
              <Button key={'save'} onClick={() => modChanges(null, true)} disabled={Object.keys(changes).length === 0}>
                Save Changes
              </Button>
            ] : [
              <Button key={'close'} onClick={close}>
                Close
              </Button>,
              <Button type={"primary"} key={'edit'} icon={<EditOutlined/>} onClick={() => setEdit(true)}>
                Edit
              </Button>
            ]}
        </Space>
      )}
      onClose={close}
      width={450}
      destroyOnClose
      visible={!!preview}
      onCancel={() => setPreview()}>
      {preview && (
        <FulcrumForm
          app={app}
          filterFieldValues={app && app.filterFieldValues}
          filterFields={edit && app.updateFields}
          hideFields={app.hideFields}
          edit={edit}
          keyField={'data_name'}
          elements={elements
            .filter(elem => elem.type === 'Section' ? true : !elem.hidden)}
          initialValues={preview}
          onValuesChange={(cgs) => setChanges({...changes, ...cgs})}
        />
      )}
      <BackTop
        visibilityHeight={10}
        style={{right: 15, bottom: 60}}
        target={() => document.getElementsByClassName('ant-drawer-body')[0]}
      />
    </Drawer>
  );
};

export default PreviewRecord;

