import React from "react";
import PageLoader from "../components/page-loader";
import {Image} from "antd";
import qs from "qs"
import NotFoundPage from "./not-found.page";
import LogoFull from "../assets/maestro.png";

export default function ImagePage({history, location}) {
  const [loading, setLoading] = React.useState(true);
  const [images, setImages] = React.useState([]);
  const [err, setErr] = React.useState(false);

  React.useEffect(() => {
    try {
      const query = qs.parse(location.search.slice(1))
      const keys = query.keys?.split(",") || []
      setImages(keys.map((k) => `https://6omu3nsz3srdcvuaph4msqkhpi0vulxk.lambda-url.us-east-1.on.aws?image=${k}.jpg`))
    } catch(e) {
        setErr(true)
    }
    setLoading(false)
  }, []);

  return loading ? <PageLoader /> : (
    (err || images.length === 0) ? (
      <NotFoundPage history={history} message={"No Images Found"} />
    ) : (
      <div>
        <div style={{padding: 10, textAlign: 'center', marginBottom: 10}}>
          <a href="/" className="href">
            <img style={{maxWidth: '100%'}} src={LogoFull} alt={'Maestro by The Sequel Group'} />
          </a>
        </div>
        <hr style={{borderColor: "#00000020"}}/>
        <div style={{height: "100%", width: "100%", textAlign: "center", padding: "50px 6px", display: "grid", gridColumn: 3, gap: "6px",gridTemplateColumns: "50% 50%"}}>
          {images.map((img, i) => (
              <div style={{maxWidth: "100%", maxHeight: 300, display: "grid", gridAutoColumns: "auto", alignItems: "center", justifyContent: "center", gap: 6}}>
                <Image key={i} fallback={"https://via.placeholder.com/150/001529/808080?text=Image%20Not%20Found"} preview={{src: img}} src={img} style={{maxHeight: 200, maxWidth: 300}} />
              </div>
          ))}
        </div>
        <hr style={{borderColor: "#00000020"}}/>
        <div style={{padding: 10, textAlign: "center", color: '#999', fontSize: 11}}>
          © {new Date().getFullYear()} The Sequel Group
        </div>
      </div>
    )
  );
}
