import React from "react";
import {Button, Card, Divider, List, Popconfirm, Space, Tooltip, Typography} from "antd";
import {CloseOutlined, DeleteOutlined, ReconciliationOutlined} from "@ant-design/icons";
import {useRecordStore} from "../../../hooks/useRecords.hook";
import UpdateRecords from "./update-records";

const SelectedRecords = () => {
	const {state: {selectedRecords}, actions: {setSelectedRecords}} = useRecordStore();

	return (
		<Card size={'small'} style={{padding: 0}} bodyStyle={{padding: 5}}>
			<Card.Meta
				avatar={<ReconciliationOutlined/>}
				title={<Typography.Text style={{fontSize: 13}}>Selected Records ({selectedRecords.length})</Typography.Text>}
			/>
			<Space style={{width: '100%', marginTop: 10}}>
				<Popconfirm title={'Are you sure?'} onConfirm={() => setSelectedRecords([])}>
					<Tooltip title={'Clear selected records'} placement={'right'}>
						<Button danger size={'small'} icon={<CloseOutlined />} disabled={selectedRecords.length === 0}>
							clear
						</Button>
					</Tooltip>
				</Popconfirm>
				<UpdateRecords />
			</Space>
			<Divider style={{margin: '15px auto'}}/>
			<List
				style={{maxHeight: 200, overflowY: 'auto', overflowX: 'hidden'}}
				size={'small'}
				dataSource={selectedRecords}
				renderItem={item => (
					<List.Item
						style={{fontSize: 10,padding: 2}}
					>
						<Typography.Text style={{width: 150, textOverflow: 'ellipsis', overflow: 'hidden', height: 40, margin: '0 2px'}}>
							{item['title'] || 'No Title'} <br/>
						</Typography.Text>
						<span>
								<Popconfirm title={'Are you sure'} onConfirm={() => setSelectedRecords(selectedRecords.filter(res => res.id !== item.id))}>
									<Tooltip title={'remove'} placement={'right'}>
										<Button size={'small'} danger icon={<DeleteOutlined/>}/>
									</Tooltip>
								</Popconfirm>
							</span>
					</List.Item>
				)}
			/>
		</Card>
	);
};

export default SelectedRecords;
