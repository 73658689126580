import React from "react";
import {Client} from 'fulcrum-app';
import PropTypes from 'prop-types';
import _ from "lodash";
import {useAuthStore} from "../hooks/useAuth.hook";

const FulcrumObject = ({token, route, id, children, fn = 'search'}) => {
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState(null);

	const {state: {auth}} = useAuthStore();

	const client = auth && new Client(auth.token);

	const _getData = () => {
		setLoading(true);
		client && client[route].find(id)
			.then(res => {
				setData(res);
				setLoading(false);
			});
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getData = React.useCallback(
		_.debounce(() => _getData(), 300),
		[]
	);

	React.useEffect(() => {
		getData();
	}, []);

	children = children && typeof children === 'function' && children({
		getData,
		data,
		loading
	});

	return (children || null);
}

FulcrumObject.propTypes = {
	route: PropTypes.oneOf([
		'memberships',
		'authorizations',
		'roles',
		'forms',
		'records',
		'choiceLists',
		'classificationSets',
		'projects',
		'layers'
	])
};

export default FulcrumObject;
