import React from "react"
import {Button, Modal, Tooltip} from "antd"
import {ExportOutlined} from "@ant-design/icons"
import _ from "lodash"
import {useAppStore} from "../../../hooks/useApp.hook"
import shortid from "shortid"
import * as XLSX from "xlsx";

const ExportData = ({records}) => {
  const {state: {fields}} = useAppStore();

  const formatRecords = (recs, cols) => {
    const imgCols = cols.filter(col => col.type === 'PhotoField')
        .map(f => f['data_name']);
    cols = cols.map(f => f.data_name);
    cols = ["id", ...cols, ...Object.keys(recs[0]).filter(k => k.slice(0, 2) === "__") ];
    let eRecs = recs.map(rec => _.pick(rec, cols));
    return eRecs.map(rec => {
      for (let i = 0; i < imgCols.length; i++) {
        const k = imgCols[i];
        rec[k] = rec[k] ?
            `${window.location.protocol}//${window.location.host}/images?keys=${rec[k]}`:
            rec[k];
      }
      return rec;
    });
  }

  const exportData = () => {
    Modal.confirm({
      title: `Would you like to export ${records.length} records?`,
      okText: 'Export',
      onOk: () => {
        let cols = fields.filter(f => f.hidden !== true);
        let pIdx = cols
            .map((c, i)=> ({...c, i}))
            .filter((c) => c.type === 'PhotoField')
        const recs = formatRecords(records, cols)
        const ws = XLSX.utils.json_to_sheet(recs)
        for (let i = 1; i < recs.length + 1; i++) {
          pIdx.forEach(col => {
            ws[XLSX.utils.encode_cell({
              c: col.i + 1,
              r: i
            })].l = { Target: recs[i-1][col.data_name] };
          })
        }
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Responses')
        XLSX.writeFile(wb, shortid() + '-maestro-export.xlsx')
      }
    })
  };

  return (
    <Tooltip title={'Export'} key={'1'}>
      <Button icon={<ExportOutlined />} onClick={exportData}/>
    </Tooltip>
  );
}

export default ExportData;
